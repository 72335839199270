.card_content_product {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-content: center;
}

.card_Products img,
.card_Products_add img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
}

.card_Products p,
.card_Products_add p {
  font-size: 14px;
  color: gray;
  font-weight: 200;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.card_Products h3,
.card_Products_add h3 {
  font-size: 18px;
  color: black;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.card_Products h2,
.card_Products_add h2 {
  font-size: 16px;
  color: black;
  font-weight: 300;
  margin: 0;
}

.card_Products,
.card_Products_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
  padding: 15px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  cursor: pointer;
  max-height: 170px;
}

.card_Products_add {
  background-color: #90c33c;
}

.card_Products_add h3,
.card_Products_add p,
.card_Products_add h2 {
  color: white !important;
}

.code-box-demo {
  overflow-x: auto;
}

.code-box-demo .ant-segmented {
  margin-bottom: 10px;
}

.alergenos_cont {
  display: flex;
  margin: 10px 0px;
}

.image_alerge{
  width: 25px !important;
  height: 25px !important;
  object-fit: cover;
  object-position: center center;
  border-radius: 100px !important;
  margin: 0 3px;

}

