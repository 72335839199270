.sesion1 {
  margin-top: 10rem;
}

.sesion1 h1 {
  text-align: center;
  font-weight: 700;
  color: black !important;
}

.sesion1__item {
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 2rem;
  margin: 0 auto;
  margin-top: 3rem;
}

.sesion1__item_cont {
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  padding: 30px;
  border-radius: 20px;
  text-align: center;
}

.sesion1__item_cont h3 {
  font-weight: 800;
}

.sesion1__item_cont p {
  color: gray !important;
}

.bg {
  width: 120px;
  height: 120px;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../Assets/images/circle.svg");
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sesion1__item {
    grid-template-columns: 100%;
  }

  .sesion1_cont {
    margin: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sesion1__item {
    grid-template-columns: 100%;
  }

  .sesion1_cont {
    margin: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sesion1__item {
    grid-template-columns: 100%;
  }

  .sesion1_cont {
    margin: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sesion1__item {
    grid-template-columns: repeat(3, 33%);
  }

  .sesion1_cont {
    margin: 0px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sesion1__item {
    grid-template-columns: repeat(3, 33%);
  }

  .sesion1_cont {
    margin: 0px;
  }
}
