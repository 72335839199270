.cont_dashboar {
  display: grid;
  grid-template-columns: 20% 80%;
  background-color: #f4f5f5;
}

.cont_dashboar_menu {
  background-color: #eee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0;
}

.cont_dashboar_menu img {
  width: 150px;
}

.logo_container {
  height: 100px;
}

.menu_container {
  height: 75vh;
}

.city_content {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.city_content p {
  font-size: 18px;
  color: #999999;
}

.content_full {
  margin: 30px;
  max-height: 87vh;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  background-color: #f4f5f5;
}

.content_full::-webkit-scrollbar {
  display: none;
}

.loading_cont {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 767px) {
  .app_movile {
    display: none;
  }

  .cont_dashboar {
    display: grid;
  }
}

