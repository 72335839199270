.semented_control {
  background-color: #e3e3e3;
  padding: 0px;
  border-radius: 100px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  align-items: center;
  justify-content: center;
}

.btn_active {
  border: none;
  background-color: #90c33c;
  color: white;
  margin: 0px 5px;
  padding: 10px 20px;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
}

.btn {
  border: 2px solid #90c33c;
  background-color: transparent;
  color: #90c33c;
  margin: 0px 5px;
  padding: 10px 20px;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
}

.search {
  margin-left: auto;
  margin-right: 10px;
  width: 300px;
  height: 50px;
  border: 1px solid #cecece;
  background-color: #c4c4c4;
  border-radius: 100px;
  display: flex;
  align-items: center;
}

.search input {
  width: 260px;
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 100px;
  background-color: transparent;
  outline: none;
  font-family: "Poppins";
  font-weight: 200;
  color: #4b4b4b;
  font-size: 18px;
}

.content_order {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.nodata_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal_contents_loading {
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #ebebeb;
  padding: 10px 0;
  width: 100%;
  min-width: 500px;
}

.items h2 {
  font-size: 14px;
  font-weight: 700;
  padding-top: 7px;
  margin: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .semented_control {
    grid-template-columns: 100%;
  }

  .search {
    margin-left: 0;
  }

  .search_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .semented_control {
    grid-template-columns: 100%;
  }

  .search {
    margin-left: 0;
  }
  .search_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .semented_control {
    grid-template-columns: 100%;
  }

  .search {
    margin-left: auto;
  }

  .search_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .semented_control {
    grid-template-columns: repeat(3, 33%);
  }

  .search {
    margin-left: auto;
  }

  .search_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .semented_control {
    grid-template-columns: repeat(3, 33%);
  }

  .search {
    margin-left: auto;
  }

  .search_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}
