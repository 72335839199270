.Footer {
  width: 100%;
  height: auto;
  background-color: #eeeeee;
  flex-shrink: 0;
}

.Footer-Content {
  max-width: 1130px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  gap: 4rem;
  margin: 0 auto;
  padding: 30px 0;
}

.Subfooter {
  padding: 0 20px;
  align-self: center;
  justify-content: center;
  border-bottom: 0.5px solid gainsboro;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 30px;
}

.SuBitemsxs p {
  color: #212121;
  font-size: 16px;
}

.SuBitemsxs ul {
  text-decoration: none;
  color: #212121;
  list-style: none;
}

.SuBitemsxs li {
  text-decoration: none;
}

.SuBitemsxs a {
  color: #212121;
  line-height: 35px;
  display: block;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.SuBitemsspan {
  text-decoration: none;
  color: #90c33c;
  font-size: 16px;
  font-weight: bold;
}

.firma {
  text-align: center;
  padding: 20px 0;
}

.logos {
  width: 120px;
  height: 50px;
}

.googl {
  width: 120px;
  height: 40px;
  margin-top: 5px;
}

.icons {
  color: #212121;
}

.icons:hover {
  color: #90c33c;
}

.item_contact {
  width: 100%;
  height: 60px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Footer-Content {
    grid-template-columns: repeat(1, 100%);
    text-align: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Footer-Content {
    grid-template-columns: repeat(1, 100%);
    text-align: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Footer-Content {
    grid-template-columns: repeat(2, 50%);
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Footer-Content {
    max-width: 1130px;
    width: 100%;
    grid-template-columns: 25% 15% 15% 10%;
    text-align: left;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Footer-Content {
    max-width: 1130px;
    width: 100%;
    grid-template-columns: 25% 25% 25% 15%;
    text-align: left;
  }
}
