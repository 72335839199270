.Login_Container {
  margin: 0;
  padding: 0;
  background-color: white;
  background-image: url("../../Assets/images/franja.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: right;
  width: 100%;
  height: 100vh;
}

.Login_Box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__box {
  position: relative;
  background-color: white;
  width: 350px;
  z-index: 1;
  margin: 20px;
  padding: 30px 30px;
  border-radius: 30px;
  box-shadow: 0 6px 36px 0 rgba(0, 0, 0, 0.08);
}

.auth__box-logo {
  text-align: center;
  display: flex;
  justify-content: center;
}

.auth__box-logo img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center center;
}

.auth__box-form {
  width: 300px;
  margin: 30px auto;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
