.nav.shadow {
  position: relative;
  background-color: white;
  position: fixed;
  z-index: 99;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  margin-top: 0px;
  width: 100%;
  height: 80px;
  padding: 10px 10px !important;
  justify-content: center;
  display: flex;
  align-items: center;
}

.nav {
  position: relative;
  width: 100%;
  padding: 10px 10px !important;
  background-color: transparent;
  position: fixed;
  z-index: 99;
  margin-top: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.cont {
  width: 100%;
  max-width: 1250px;
  display: flex;
  align-items: center;
}

.cont a img {
  width: 140px;
  object-fit: cover;
  object-position: center center;
}

.cont_rigth {
  margin-left: auto;
}

.cont_rigth ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.cont_rigth li {
  float: left;
}

.cont_rigth a {
  display: block;
  color: #2d2861;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.cont_rigth a:hover {
  color: #2d2861;
}

.btn_Login {
  padding: 10px 26px !important;
  border: none;
  background-color: #2d2861;
  outline: none;
  cursor: pointer;
  color: white !important;
  border-radius: 100px;
  margin-top: 5px;
  margin-left: 10px;
}

.logosmall {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .links {
    display: none;
  }

  .logosmall {
    display: inline;
  }

  .logo {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .links {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .links {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .links {
    display: inline;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .links {
    display: inline;
  }
}
