.barra {
  text-decoration: none;
}
.barra ul {
  list-style: none;
  text-decoration: none;
}

.barra li {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 1.5rem 0;
}

.menu_inactive span {
  font-size: 22px;
  color: #999999;
}

.menu_inactive button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #999999;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.LogOut span {
  color: red !important;
}

.menu_active span {
  font-size: 20px;
  color: #90c33c;
}

.menu_active button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #90c33c;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}
