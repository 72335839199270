.form_content {
  width: 100%;
  max-width: 500px;
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  padding: 30px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
}

.form_content Input,
Select,
Option,
.ant-select-selector {
  height: 50px;
}
