.add_cont {
  border: 2px dashed #90c33c;
  border-radius: 10px;
  height: auto;
  min-height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}

.add_cont_bundled {
  border: 2px dashed #90c33c;
  border-radius: 10px;
  height: auto;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  margin-top: 30px;
}

.add_cont p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #90c33c;
  font-weight: 300;
}

.imagen_prod_add {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center center;
}

.ant-upload-text p {
  font-size: 16px;
  color: #90c33c;
  font-weight: 300;
}

.content_modal {
  margin: 10px 20px;
}

.content_modal {
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.add_info {
  margin-top: 2rem;
}

.input_price {
  margin-top: 1rem;
}

.add_info p {
  margin: 0;
  font-size: 14px;
  font-weight: 200;
  color: gray;
}

.btn_contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  gap: 10px;
}

.btn_item {
  height: 40px;
  width: 100%;
  background-color: gainsboro;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.new {
  background-color: #ffadd2;
}

.new p {
  color: #c41d7f !important;
}

.New {
  background-color: #c41d7f !important;
  outline: none !important;
}

.NoNew {
  background-color: #db83b7 !important;
  outline: none !important;
}

.offert {
  background-color: #ffc8ad;
}

.offert p {
  color: #c4521d !important;
}

.Off {
  background-color: #c4521d !important;
  outline: none !important;
}

.NoOff {
  background-color: #8c9ce9 !important;
  outline: none !important;
}

.popu {
  background-color: #eaff8f;
}

.popu p {
  color: #7cb305 !important;
}

.btn_item p {
  font-size: 15px;
  font-weight: 300;
  color: gray;
}

.name_product {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.elergens {
  border: 1px solid gainsboro;
  border-radius: 8px;
  background-color: transparent;
  padding: 5px;
  margin: 7px;
  outline: none;
  cursor: pointer;
  color: black;
}

.elergens_active {
  border: 1px solid #7cb305;
  background-color: #7cb30535;
  border-radius: 8px;
  padding: 5px;
  margin: 7px;
  outline: none;
  cursor: pointer;
  color: #7cb305;
}

.elergens img, .elergens_active img{
  width: 20px;
  height: 20px;
  object-fit: cover;
  object-position: center center;
  border-radius: 100px;
  margin-right: 5px;
  font-weight: 500;
}
