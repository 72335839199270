.tabs_cont button{
    background-color: transparent;
    outline: none;
    border-bottom: 2px solid gray;
    border-top: none;
    border-left: none;
    border-right: none;
    cursor: pointer;
    margin: 10px;
    font-size: 16px;
    font-weight: 600;
    color: gray;

}


.tabs_active {
    border-bottom: 2px solid #90c33c !important;
    color: #90c33c !important;
}