.sesion4 {
  width: 100%;
  margin-top: 10rem;
}

.sesion4 h1 {
  font-weight: 900;
  text-align: center;
}

.sesion4_cont {
  width: 100%;
  max-width: 1300px;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  gap: 2rem;
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 20px;
  margin: 5rem auto;
}

.sesion4_cont div {
  padding: 10px;
}

.sesion4_cont div img {
  width: 130px;
  text-align: center;
}
.sesion4_cont div h3 {
  font-weight: 900;
  text-align: center;
}
.sesion4_cont div p {
  color: gray !important;
  text-align: center;
}

.liogon {
  width: 90%;
  height: auto;
  background-color: #2d2861;
  background-image: url("../../../Assets/images/franja.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: right;
  max-width: 1300px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left !important;
  padding: 20px 20px;
  margin: 20px auto;
}

.liogon a {
  margin-left: auto;
  background-color: #2d2861;
  color: white;
  padding: 10px 30px;
  border-radius: 100px;
  margin-left: auto;
}

.liogon h1 {
  text-align: left !important;
  font-weight: bold;
  color: white;
}

.liogon p {
  font-weight: 200;
  color: white;
}

.more {
  text-align: center;
  margin: 8rem 0;
  padding: 0 20px;
}

.more h1 {
  font-weight: 900;
  color: black !important;
}

.more p {
  color: gray !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sesion4_cont {
    grid-template-columns: 100%;
    text-align: center;
  }

  .sesion4 div {
    padding: 10px;
  }

  .sesion4 div img {
    width: 130px;
    text-align: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sesion4_cont {
    grid-template-columns: 100%;
    text-align: center;
  }

  .sesion4 div {
    padding: 10px;
  }

  .sesion4 div img {
    width: 130px;
    text-align: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sesion4_cont {
    grid-template-columns: 100%;
    text-align: center;
  }

  .sesion4 div {
    padding: 10px;
  }

  .sesion4 div img {
    width: 130px;
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sesion4_cont {
    grid-template-columns: repeat(3, 32%);
    text-align: center;
  }

  .sesion4 div {
    padding: 10px;
  }

  .sesion4 div img {
    width: 130px;
    text-align: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sesion4_cont {
    grid-template-columns: repeat(3, 32%);
    text-align: center;
  }

  .sesion4 div {
    padding: 10px;
  }

  .sesion4 div img {
    width: 130px;
    text-align: center;
  }
}
